import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Header from "../components/headers/header"
import Banner from "../components/banner"
import Footer from "../components/footers/footer"

export default function pageTemplate({data}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  return (
    <div className="bg-white">
      <Helmet>
        <title>{frontmatter.title_meta}</title>
        <meta name="description" content={frontmatter.description_meta} />
      </Helmet>

      <header className="sticky top-0 z-50 shadow-lg bg-white">
        <Banner />
        <Header />
      </header>

      <main className="relative">
        <div className="bg-gray-50 pt-12 sm:pt-16">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div class="flex flex-1 overflow-hidden pr-4">
                  <div class="flex flex-1 flex-col">
                    <div class="flex flex-1 overflow-y-auto paragraph">
                      <div className="relative overflow-hidden">
                        <div className="relative px-4 sm:px-6 lg:px-8">
                          <div className="text-lg max-w-prose mx-auto">
                            <h1>
                              <span className="mt-2 block text-3xl text-left leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                {frontmatter.title}
                              </span>
                            </h1>
                          </div>

                          <div 
                            className="mt-6 prose prose-sky prose-lg text-gray-500 mx-auto"
                            dangerouslySetInnerHTML={{ __html: html }}
                          />

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
          </div>
        </div>
      </main>
      
      <footer>
        <Footer />
      </footer>
    </div>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        title_meta
        description_meta
        excerpt
      }
    }
  }
`
